import React,{Fragment,useContext, useEffect,useState} from 'react';
import {Link} from 'react-router-dom';
import loading_img from "../../../images/profile/loading.gif";
import { useParams, Router, useLocation } from "react-router-dom";
import { GlobalSet } from '../global/GlobalProvider';
//Import Components
import axios from "axios";
import { Row, Col, Card, Carousel,Accordion } from 'react-bootstrap'
import Slider from "react-slick";
import swal from "sweetalert";

const Home = () => {


   const location = useLocation();
   const urlSearchParams = new URLSearchParams(location.search);
   const provider = urlSearchParams.get('provider');
   let  providerGet;
   if (provider !== undefined && provider !== null && provider !== '') {
     providerGet = provider;
   }else{
     providerGet = "";
   }
   const { id } = useParams();
   const { BaseUrl } = GlobalSet();
   const [homeitem, setHomeItem] = useState([]); 
   const [salider, setSalider] = useState([]);
   const [Facetyoe, gameFacetyoe] = useState([]);
   const [homeCasino, setCasino] = useState([]);   
   const [loading, setLoading] = useState(true);
   const [search, setQuery] = useState('');

   const [selectedTypes, setSelectedTypes] = useState([]);


  const [visibleItems, setVisibleItems] = useState(80); // Initial number of items to display
  const [totalItems, setcount_game] = useState([]);
  const [var_casino, all_providers] = useState([]);
  useEffect(() => {
  const source = axios.CancelToken.source();
  const fetchData = async () => {
    try {
      const data = {
        key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
        slug: id,
        search: search,
        provider: providerGet,
        selectedTypes: selectedTypes, // Include selected types in the request
      };

      const response = await axios.post('/casino-filters', data, {
        cancelToken: source.token,
      });

      setHomeItem(response.data.data);
      setcount_game(response.data.count_game);
      all_providers(response.data.all_providers);
      setSalider(response.data.slider);
      gameFacetyoe(response.data.game_face_tyoe);
      setLoading(false);
    } catch (error) {
      if (axios.isCancel(error)) {
        // Request was canceled, ignore
      } else {
        console.error('Error fetching data:', error);
      }
    }
  };

  // Only fetch data if the query or selectedTypes change
  if (search.trim() !== '' || selectedTypes.length > 0 || !loading) {
    fetchData();
  } else {
    // Clear data if the query or selectedTypes are empty
    setHomeItem([]);
    setSalider([]);
    setCasino([]);
    setLoading(false);
  }

  // Cleanup function to cancel the request if the component unmounts
  return () => source.cancel();
  }, [id, search, providerGet, selectedTypes, loading]); // Add selectedTypes to the dependency array

  const handleCheckboxChange = (type) => {
    setSelectedTypes((prevSelected) =>
      prevSelected.includes(type)
        ? prevSelected.filter((item) => item !== type) // Remove if already selected
        : [...prevSelected, type] // Add if not selected
    );
  };


  const handleSeeMore = () => {
    // Increase the number of visible items when "See More" is clicked
    setVisibleItems(visibleItems + 80); // Adjust the number as needed
  };

//mobile odds option
  const casino_game_s = {
    infinite: true,
    arrows: true,
    speed: 100,
    slidesToShow: 10,
    slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1560,
                settings: {
                  slidesToShow: 8,
                  slidesToScroll: 7,
                },
            },
            {
                breakpoint: 1400,
                settings: {
                  slidesToShow: 7,
                  slidesToScroll: 5,
                },
            },  
            {
                breakpoint: 640,
                settings: {
                  slidesToShow: 5,
                  slidesToScroll: 3,
                },
            },
        ],
  };

return(
<>
   <span style={{  marginTop: '-36px' }} className="d-xl-block">
      <div className="row">
      <Fragment>
          <Col xl="12" className="p-0" >
            <Card  >

            <Carousel className="p-0">
              {salider.map((carousel, i) => (
                <Carousel.Item key={i}>
                  <img
                    src={BaseUrl+(carousel.img_link)}
                    className='d-block w-100 mb-0'
                    alt={`Slide ${i + 1}`}
                  />
                </Carousel.Item>
              ))}
            </Carousel> 

                <div className="col-xl-12">
                    <div className="card-body pt-1 pb-2 rounded">
                      <Slider className="front-view-slider owl-carousel owl-carousel owl-loaded owl-drag owl-dot" {...casino_game_s}>   
                         {var_casino.map((product)=>(
                           <div className="p-1">  
                                <div>
                                  <Link to={"/home-game/all-game?provider="+product.slug} className="text-black user-name">
                                         <div className="img-bx">
                                          <img style={{margin: "0px auto", width: "30%"}} src={BaseUrl + product.icon} alt="" className=" card-list-img w-10"/>
                                             <span className="nav-text text-center"  style={{display: "block"}}> {product.providers_name}</span>
                                      </div>  
                                   </Link>
                                </div>  
                            </div>
                           ))}
                      </Slider>
                    </div>  
                </div>



                <div>
                  <div className="input-group  input-info">
                    <span className="input-group-text"><span className="fa fa-search"></span></span>
                    <input type="text" className="form-control" value={search} onChange={(e) => setQuery(e.target.value)} />
                    <span className="input-group-text">Search</span>
                  </div>
                </div>


                  <div className="col-xl-12 col-lg-12">
                    <div className="scroll-container">
                      <div className="checkbox-row">
                        {Facetyoe.map((type, index) => (
                          <div className="checkbox-item" key={index}>
                            <div className="form-check custom-checkbox checkbox-info">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id={`customCheckBox${index}`}
                                onChange={() => handleCheckboxChange(type)}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`customCheckBox${index}`}
                              >
                                {type}
                              </label>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <style jsx>{`
                      .scroll-container {
                        overflow-x: auto;
                        white-space: nowrap;
                        padding: 10px;
                        display: flex;
                        justify-content: center; /* Centers the content horizontally */
                        align-items: center; /* Centers the content vertically if needed */
                        height: 100%; /* Ensure it takes the full height if required */
                      }
                      .checkbox-row {
                        display: flex;
                        gap: 15px;
                        justify-content: center; /* Centers the checkbox items */
                      }
                      .checkbox-item {
                        flex: 0 0 auto;
                      }
                    `}</style>
                  </div>


              <Card.Body className="p-2" >
              <div className="form-inline">
                <div className="row">
                   {homeitem.slice(0, visibleItems).map((ele) => (
                      <div className="col-xl-2 col-xxl-3 col-lg-3 col-md-4 col-sm-6 col-6 int-col p-1">   
                            <div key={ele.id}>
                              <Link to={"/casino-game-get/"+ele.id+"/"+ele.slug} className="text-black user-name">

                                  <div className="img-bx">
                                      <img src={(ele.image)} alt="" className=" me-3 card-list-img w-100" width="130" />
                                  </div>  
                               </Link>
                            </div>  
                        </div>  
                        ))}
                </div>
              </div>

             {visibleItems < totalItems && (
                <div className="row text-center">
                  <div>
                    <button onClick={handleSeeMore} className="btn btn-primary mt-2">
                      See More > > > >
                    </button>
                   </div>  
                 </div>  
                )}

              </Card.Body>


            </Card>
          </Col>
      </Fragment>

      </div>
    </span>
    </>
  )
}
export default Home;